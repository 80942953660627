// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import {generalReducer,generalNotificationsReducer} from './general'
import dataTables from '@src/views/manage-users/store/reducer'
import requests from '@src/views/requests/store/reducer'
import rules from '@src/views/settings-page/store/reducer'
import chat from '@src/views/chat/store/reducer'



const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  chat,

  dataTables,
  general:generalReducer,
  notifications:generalNotificationsReducer,
  requests,
  rules
})

export default rootReducer
