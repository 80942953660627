const initialState = {
  chats: [],
  contacts: [],
  userProfile: {},
  conversations: [],
  selectedUser: {},
  selectedMsgs: [],
  stats: {
    allUnRead:0,
    advUnRead:0,
    infUnRead:0
  }
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile }
      case 'GET_CONVERSATIONS':
        return { ...state, conversations: action.conversations, stats: action.stats,}
    case 'GET_CHAT_CONTACTS':
      return { ...state, chats: action.data.chatsContacts, contacts: action.data.contacts }
    case 'SELECT_CHAT':
      return { ...state, selectedUser: action.user, selectedMsgs:action.data }
    case 'SEND_MSG':
      // ** Add new msg to chat
      return { ...state, selectedMsgs: [...state.selectedMsgs, action.data ] }
    default:
      return state
  }
}

export default chatReducer
