// ** Initial State
const initialState = {
  rules: {},
          advertisers_profits_rules: [],
          influencers_profits_rules: [],
          tax:  0,
};

const RulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RULES":
      return {
        ...state,
       ...action
      };
    default:
      return state;
  }
};

export default RulesReducer;
